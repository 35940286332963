import RequestApi from 'lib/labelify-api';

/**
 * Get allergies
 */
export async function GetAllergies({ sessionId = null, responseHandler = null } = {}) {
  const [responseData, response] = await RequestApi({
    url: 'utils/allergies',
    sessionId
  })

  return (response !== null && response.status == 200) ? responseData : false;
}

/**
 * Get available units
 * @param {bool} volume 
 * @param {bool} mass 
 */
export async function GetAvailableUnits({ volume = null, mass = null, sessionId = null, responseHandler = null } = {}) {
  const [responseData, response] = await RequestApi({
    url: 'utils/available_units',
    body: {
      volume: volume,
      mass: mass,
    },
  })

  return (response !== null && response.status == 200) ? responseData : false;
}

/**
 * Get yield units
 */
export async function GetYieldUnits({ sessionId = null, responseHandler = null } = {}) {
  const [responseData, response] = await RequestApi({
    url: 'utils/yield_units',
  })

  return (response !== null && response.status == 200) ? responseData : false;
}

/**
 * Get concrete types
 */
export async function GetConcreteTypes({ sessionId = null, responseHandler = null } = {}) {
  const [responseData, response] = await RequestApi({
    url: 'utils/concrete_types',
  })

  return (response !== null && response.status == 200) ? responseData : false;
}

/**
 * Get label rules
 */
export async function GetLabelRules({ concreteType = 'CAN2016', sessionId = null, responseHandler = null } = {}) {
  const [responseData, response] = await RequestApi({
    url: 'utils/label_rules/' + concreteType,
    sessionId,
  })

  return (response !== null && response.status == 200) ? responseData : false;
}

/**
 * Get fop rules
 */
export async function GetFopRules({ concreteType = 'CAN2016', sessionId = null, responseHandler = null } = {}) {
  const [responseData, response] = await RequestApi({
    url: 'utils/fop_label_rules/' + concreteType,
    sessionId,
  })

  return (response !== null && response.status == 200) ? responseData : false;
}

/**
 * Get nutriment rules
 */
export async function GetNutrimentRules({ sessionId = null, responseHandler = null, former = false } = {}) {
  const [responseData, response] = await RequestApi({
    url: 'utils/nutriments_rules',
    sessionId,
    body: {
      former: former
    }
  })

  return (response !== null && response.status == 200) ? responseData : false;
}

/**
 * Get class names
 */
export async function GetClassNames({ sessionId = null, responseHandler = null } = {}) {
  const [responseData, response] = await RequestApi({
    url: 'utils/class_names',
  })

  return (response !== null && response.status == 200) ? responseData : false;
}

/**
 * Get countries
 */
export async function GetCountries({ sortBy = null, sessionId = null, responseHandler = null } = {}) {
  const [responseData, response] = await RequestApi({
    url: 'utils/countries',
    body: {
      sort: sortBy
    },
  })

  return (response !== null && response.status == 200) ? responseData : false;
}

/**
 * Get regions
 */
export async function GetRegions({ countryCode, sortBy = null, sessionId = null, responseHandler = null }) {
  const [responseData, response] = await RequestApi({
    url: 'utils/countries/' + countryCode + '/regions',
    body: {
      sort: sortBy
    },
  })

  return (response !== null && response.status == 200) ? responseData : false;
}

/**
 * Lang name
 * Helper function to resolve name vs name_en vs name_fr
 */
export function LangName(item, locale = 'en', key = 'name') {
  return item[key + '_' + locale];
}
